body,
.ant-layout {
  background: #f4f5f8;
  font-family: 'Roboto Condensed', sans-serif !important;
}
.app-header {
  background: #fff !important;
}
.app-header .nav-center {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}
.app-header .nav-center .ant-typography {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 35px;
  font-family: 'Roboto Condensed', sans-serif !important;
}
.app-header .nav-left {
  display: flex;
  margin-left: 20px;
}
.app-header .nav-right {
  position: absolute;
  right: 20px;
}
.full-width-row {
  width: 100%;
}
.filter-select-100w {
  width: 100%;
}
.filter-select-25w {
  width: 25%;
}
.ant-breadcrumb {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.ant-breadcrumb-link a {
  color: #1890ff;
  font-weight: 700;
}
.ant-breadcrub-link a:hover {
  color: #1890ff;
}
.ant-breadcrumb-link {
  color: #231f20;
  font-weight: 400;
}
.ca-toast {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  line-height: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;
}
.info-toast {
  border: 1px solid #8b8d8f;
  background-color: #d4d5d8;
  color: #8b8d8f;
}
.alert-toast {
  border: 1px solid #ec7a07;
  background-color: #f1cfae;
  color: #ec7a07;
}
.success-toast {
  border: 1px solid #36b44c;
  background-color: #d3e9da;
  color: #36b44c;
}
.warning-toast {
  border: 1px solid #ef5869;
  background-color: #f3dadf;
  color: #ef5869;
}
.nav-forecast-info {
  margin-top: 15px;
}
.nav-forecast-info .ant-typography {
  display: inline;
  font-size: 15px;
}
.board-sort-section {
  text-align: right;
  margin-top: 15px;
}
.board-sort-section span {
  margin-right: 5px;
}
.board-sort-icon {
  padding-left: 5px;
  padding-right: 5px;
}
.input-label {
  margin-bottom: 0px !important;
  font-size: 14px !important;
}
.changes-group-title {
  width: 250px;
}
.changes-group-list {
  width: 90%;
  margin-left: 24px;
}
.changes-list {
  width: 45%;
}
.loading-row {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading-gif {
  height: 150px;
  width: 200px;
}
.loading-text {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 18px;
  color: #1890ff;
  text-align: center;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.red-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f5222d;
  margin-right: 10px;
}
.green-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #52c41a;
  margin-right: 10px;
}
.ant-table-thead > tr > th {
  font-weight: 700;
}
.users-table {
  margin-top: 24px;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}
.wos-err, .wos-high, .wos-mid, .wos-low {
  text-align: center;
  font-weight: bold;
}
.wos-err {
  background-color: #9254de !important;
  color : white;
}
.wos-high {
  background-color: #fadb14 !important;
}
.wos-mid {
  background-color: gray !important;
  color : white;
}
.wos-low {
  background-color: #f5222d !important;
  color : white;
}
.inventory-table-card .ant-card-extra {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}
.extend-forecast-btn {
  padding: 4px 8px;
  color: #9254de !important;
  font-weight: bolder !important;
}
