.filters-row {
  background: #fff;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  padding: 10px;
}
.filter-title {
  font-family: 'Roboto Condensed', sans-serif !important;
}
