.save-board-section {
  text-align: right;
  margin-bottom: 10px;
}

.save-board-section input {
  border: 0px;
  border-bottom: 1px solid #40a9ff;
  background: inherit;
  border-radius: 0px;
}

.save-board-section input:focus {
  box-shadow: none;
}

.select-prompt-text {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}

.rendering-progress {
  position: absolute;
  height: 200%;
  background-color: gray;
  opacity: 0.35;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.spinner {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  z-index: 2;
  font-weight: 700;
}
