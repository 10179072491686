.modelling-changes-item {
  justify-content: flex-start !important;
  margin-bottom: 20px !important;
}

.modelling-actions {
  margin-bottom: 20px;
}

.inventory-input {
  width: 200px;
  margin-left: 10px;
}

.inventory-value-text {
  margin: 10px;
}
