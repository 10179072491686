.chart-type-row, .change-row {
    margin-top: 24px;
}

.change-row .chart-title {
    text-align: center;
}

.center-text {
    text-align: center;
    position: relative;
    top: 200px;
    height: 400px;
}