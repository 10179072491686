.analysis-tabs {
  text-align: center;
}
.chart-type-selector {
  text-align: right;
}
.chart-type-selector .ant-select {
  text-align: left;
  width: 125px;
}
